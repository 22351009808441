<template>
<div class="p-products" >
<Wrapper :bg-img="img" min-height="380px">
	<div style="height: 87px"/>
	<div style="font-size: 2.5vw" class="p-product-ani1 g-letters-58 g-color-white">{{title}}</div>
	<div style="height: 42px"/>
	<div class="p-product-ani1 g-fonts-24 g-marginb-22 g-letters-29 g-opacity-08 g-color-white">{{subtitle}}</div>
	<div style="max-width: 850px" class="p-product-ani1 g-lineh=23 g-letters-17 g-opacity-08 g-color-white">{{desc}}</div>
</Wrapper>
<Wrapper min-height="380px" v-if="showAdvantages">
	<div style="height: 60px"/>
	<DecoratedTitle center class="g-fonts-24 g-letters-29">服务优势</DecoratedTitle>
	<div style="height: 50px"/>
	<div class="g-justify" :class="{'farmers':$route.query.p && $route.query.p.indexOf('p')>-1}">
		<template v-for="(item, index) in advantages">
			&nbsp;
			<div class="g-display-ib p-product-ani2" style="width: 240px" :key="index">
				<img style="width: 46px; height: 42px" class="g-marginb-20" :src="item.icon"/>
				<div class="g-letters-59 g-fonts-16 g-fontw-600 g-marginb-20 f-title">{{item.title}}</div>
				<div class="g-lineh-24 g-color-gray" style="letter-spacing: 1.42px">{{item.desc}}</div>
			</div>
		</template>
	</div>
	<div style="height: 50px"/>
</Wrapper>
<Wrapper v-if="architecture" min-height="auto" bg="#FAFBFE">
	<div style="height: 70px"/>
	<DecoratedTitle center class="g-fonts-24 g-letters-29">产品架构</DecoratedTitle>
	<div style="height: 50px"/>
	<div class="g-width-100p">
		<img class="g-img-auto" :src="architecture"/>
	</div>
	<div style="height: 70px"/>
</Wrapper>
<Wrapper min-height="619px" v-if="showFunctions">
	<div style="height: 70px"/>
	<DecoratedTitle center class="g-fonts-24 g-letters-29">核心功能</DecoratedTitle>
	<div style="height: 50px"/>
	<a-row :gutter="136">
		<a-col :span="12" v-for="(item, index) in functions" :key="index">
			<div class="p-product-ani3" style="height: 220px">
				<div style="margin-bottom: -20px">
					<img style="height: 60px; width: auto" :src="item.icon"/>
				</div>
				<!-- <div class="p-product-index-n">0{{index+1}}</div> -->
				<div class="g-fonts-16 g-letters-59 g-fontw-600 g-marginb-20">{{item.title}}</div>
				<div class="g-color-gray g-letters-59 g-lineh-25">{{item.desc}}</div>
			</div>
		</a-col>
	</a-row>
</Wrapper>
<Wrapper min-height="780px" bg="#FAFBFE" v-if="showCases">
	<div style="height: 70px"/>
	<DecoratedTitle center class="g-fonts-24 g-letters-29">应用场景/合作案例</DecoratedTitle>
	<div style="height: 50px"/>
	<div class="p-product-footer">
		<div
			:style="{width: index === activeId ? activedWidth : width}"
			@mouseenter="handleChange(index)"
			class=" g-display-ib p-product-footer-item g-position-r p-ani"
			:class="{'p-product-footer-item-active' : index === activeId}"
			v-for="(item, index) in cases"
			:key="index">
			<div class="g-position-r g-height-100p">
				<div
					:class="{'g-opacity-1': index === activeId, 'g-opacity-0': index !== activeId,}"
					class="g-position-a g-top-0 g-bottom-0 g-left-0 g-right-0 p-ani">
					<img :src="item.bg || footerBg" class="g-img-fit" alt="">
				</div>
				<div class="p-product-f-logo" v-if="item.logo"><img :src="item.logo"/></div>
				<div class="p-product-f-industry p-ani" v-if="item.industry">{{item.industry}}</div>
				<div class="p-product-f-subtitle p-ani" v-if="item.subtitle">{{item.subtitle}}</div>
				<div class="p-product-f-title" v-if="item.title">—— {{item.title}}</div>
				<div class="p-product-f-desc p-ani" v-if="item.desc">{{item.desc}}</div>
				<div class="p-product-f-arr g-color-white g-texta-c">
					<a-icon type="right" />
				</div>
			</div>
		</div>
	</div>
</Wrapper>
<template v-if="pics.length">
	<Wrapper :bg="index % 2 === 0 ? '#fff' : '#FAFBFE'" :id="item.id"  v-for="(item, index) in pics" :key="item.id">
		<div :id="'p-product-p-'+item.id" style="height: 5px"/>
		<div class="g-width-100p">
			<img class="g-img-auto" :src="item.icon"/>
		</div>
		<div style="height: 70px"/>
	</Wrapper>
</template>
</div>
</template>
<script>
import { details } from '@/views/products/config'
const defaultImg = require('@/assets/img/products/product-banner2.png')

export default {
	name: 'Product',
	data() {
		return {
			footerBg: require('@/assets/img/solutions/product-example-img.jpg'),
			img: defaultImg,
			activeId: 0,
			title: '',
			subtitle: '',
			architecture: '',
			desc: '',
			cases: [],
			functions: [],
			advantages: [],
			pics: [],
		}
	},
	created() {
		this.showDetail()
	},
	watch: {
		$route(route) {
			const { path } = route
			if (path === '/product') {
				this.showDetail()
			}
		}
	},
	computed: {

		showAdvantages() {
			return Array.isArray(this.advantages) && this.advantages.length > 0
		},
		showFunctions() {
			return Array.isArray(this.functions) && this.functions.length > 0
		},
		showCases() {
			return Array.isArray(this.cases) && this.cases.length > 0
		},
		activedWidth() {
			if (!Array.isArray(this.cases) || this.cases.length === 0) {
				return
			}
			const px = 214 * (this.cases.length - 1) + 'px'
			return `calc( 100% - ${px} )`
		},
		width() {
			return '214px'
		},

	},
	methods: {
		showDetail() {
			const { query } = this.$route
			if (query.p) {
				let detail = {}
				this.pics = []
				if (['h0', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'].indexOf(query.p) !== -1) {
					detail = details.h
					this.pics = detail.pics
				} else if (details[query.p]) {
					detail = details[query.p]
				}
				this.img = detail.img || defaultImg
				switch (query.p) {
				case 'l1' : case 'o1' : case 's1' : case 'p1' :
					this.img = require('@/assets/img/solutions/product-banner3.jpg')
					break
				case 'l2' : case 'o2' : case 's2' : case 'p3' :
					this.img = require('@/assets/img/solutions/product-banner4.jpg')
					break
				case 'l3' : case 'o3' : case 's3' : case 'p4' :
					this.img = require('@/assets/img/solutions/product-banner5.jpg')
					break
				case 'l4' : case 'o4' : case 's4' : case 'p5' :
					this.img = require('@/assets/img/solutions/product-banner6.jpg')
					break
				case 'h0' : case 'h1' : case 'h2' : case 'h3' : case 'h4' : case 'h5' : case 'h6' :
					this.img = require('@/assets/img/solutions/product-banner7.jpg')
					break
				default:
					this.img = require('@/assets/img/solutions/product-banner2.png')
				}
				this.title = detail.title || ''
				this.subtitle = detail.subtitle || ''
				this.architecture = detail.architecture || ''
				this.desc = detail.desc || ''
				this.cases = detail.cases || []
				this.functions = detail.functions || []
				this.advantages = detail.advantages || []
			}
		},
		handleChange(index) {
			this.activeId = index
		}
	},
	activated() {
		const p = this.$route.query.p
		if (['h0', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'].indexOf(p) !== -1) {
			document.querySelector('#' + p).scrollIntoView(true)
		}
	}
}
</script>
<style lang="less" scoped>
.p-product-footer {
	height: 537px;
}
.p-product-index-n {
	width:64px;
	height: 75px;
	margin-bottom: -20px;
	opacity: 0.05;
	font-family: Akrobat-Black;
	font-size: 60px;
	color: #005FFF;
	letter-spacing: 3.91px;
}
.p-product-footer-item {
	height: 100%;
	vertical-align: top;
	&:nth-child(1) {
		background-color: #D4E2FA;
	}
	&:nth-child(2) {
		background-color: #E0EAFA;
	}
	&:nth-child(3) {
		background-color: #ECF1F9;
	}
	&:nth-child(4) {
		background-color: #FFFFFF;
	}
}
.p-ani {
	transition: all ease-in-out 0.3s;
}
.p-product-f-logo {
	padding: 24px;
	height: 133px;
	line-height: 133px;
	> img {
		height: 25px;
		width: auto;
	}
}
.p-product-f-industry {
	position: absolute;
	top: 130px;
	left: 24px;
	right: 24px;
	font-size: 18px;
	font-weight: 600;
	font-size: 18px;
	color: #606266;
	letter-spacing: 0.88px;
}
.p-product-f-title {
	display: none;
}
.p-product-f-subtitle {
	position: absolute;
	top: 165px;
	left: 24px;
	right: 24px;
	font-size: 16px;
	color: #8896B2;
	letter-spacing: 0.78px;
}
.p-product-f-desc {
	position: absolute;
	top: 246px;
	left: 24px;
	right: 24px;
	font-size: 16px;
	color: #8896B2;
	letter-spacing: 0.78px;
	line-height: 25px;
}
.p-product-f-arr {
	width: 40px;
	height: 40px;
	background: #005FFF;
	border-radius: 50%;
	line-height: 40px;
	position: absolute;
	bottom: 26px;
	left: 24px;
}
.p-product-footer-item-active {
	.p-product-f-logo {
		display: none;
	}
	.p-product-f-industry {
		top: 378px;
		left: 38px;
		right: 38px;
		font-size: 21px;
		font-weight: 600;
		font-size: 18px;
		color: #fff;
		letter-spacing: 1.02px;
	}
	.p-product-f-title {
		display: block;
		position: absolute;
		bottom: 21px;
		right: 38px;
		font-size: 14px;
		color: #F2F2F2;
		letter-spacing: 0.68px;
	}
	.p-product-f-subtitle {
		display: none;
	}
	.p-product-f-desc {
		position: absolute;
		top: 433px;
		left: 38px;
		right: 38px;
		font-size: 14px;
		color: #F2F2F2;
		letter-spacing: 0.68px;
	}
	.p-product-f-arr {
		display: none;
	}
}
.farmers .p-product-ani2{text-align: center;}
.farmers .p-product-ani2>img{width: 120px !important;height:  120px !important;}
.farmers .f-title{font-weight: normal;}
</style>
